import Http from './http'

// 注册，必传: mobile , password, code
export function register(params) {
    return Http({
        url: '/users/register',
        method: 'get',
        params
    })
}

// 获取短信验证码，必传: mobile, type(1 验证码 2注册码 3重置密码)
export function sendMsg(params) {
    return Http({
        url: '/users/sendMsg',
        method: 'get',
        params
    })
}

// 登录用户通过密码，必传: username, password
export function loginByPassword(data) {
    return Http({
        url: '/users/loginByPassword',
        method: 'post',
        data
    })
}

// 登录用户通过验证码，必传: username, code
export function loginByCode(data) {
    return Http({
        url: '/users/loginByCode',
        method: 'post',
        data
    })
}
// 登出
export function logout() {
    return Http({
        url: '/users/logout',
        method: 'get'
    })
}

// 修改密码 短信验证
export function verificateCode(data) {
    return Http({
        url: '/users/verificateCode',
        method: 'post',
        data
    })
}

// 修改密码 必传: username，password，code
export function repassword(data) {
    return Http({
        url: '/users/repassword',
        method: 'post',
        data
    })
}

// 1.获取系列用于下拉筛选
export function getSeries() {
    return Http({
        url: '/home/getSeries',
        method: 'get'
    })
}