<template>
    <div class="user-wrapper">
        <template v-if="isLogin">
            <a class="item finder" href="javascript:;" title="搜索"><i class="icon icon-search" /></a>
            <a v-popover:popover class="item avatar-box" href="javascript:;">
                <img class="avatar" :src="require('@a/img/logo.png')" alt="kamisoul">
                <i class="icon">!</i>
            </a>
            <el-popover
                ref="popover"
                width="90"
                placement="bottom"
                trigger="hover"
            >
                <div class="user-popover">
                    <router-link class="user-popover-item" to="/user">个人中心</router-link>
                    <a href="javascript:;" class="user-popover-item" @click="logout">退出登录</a>
                </div>
            </el-popover>
        </template>
        <template v-else>
            <router-link to="/register" class="user-link">注册</router-link>
            <router-link to="/login" class="user-link">登录</router-link>
        </template>
    </div>
</template>
<script>
import { logout } from '@api'
export default {
    name: 'UserInfo',
    data() {
        return {
            isLogin: this.$utils.getUser()
        }
    },
    methods: {
        async logout() {
            const res = await logout()
            if (res.detail.status === '1') {
                this.isLogin = false
                this.$utils.clearUser()
            }
            this.$notify({ title: '提示', type: res.detail.status === '1' ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
        }
    }
}
</script>
<style lang="scss" scoped>
    .user-wrapper {
        @include inlineBlock;
        padding: 0 20px;
        height: 100%;
        text-align: right;
        .item {
            @include inlineBlock;
            width: 50px;
            height: 50px;
            padding: 13px;
        }
    }
    .avatar-box {
        position: relative;
        .avatar {
            display: block;
            width: 100%;
            height: 100%;
        }
        .icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 50%;
            text-align: center;
            color: $white;
            background-color: $red;
        }
    }
    .user-link {
        @include inlineBlock();
        padding: 0 25px;
        margin: 10px 0;
        line-height: 30px;
        border-left: 1px solid $gray;
        &:first-child {
            border: none;
        }
    }
    .user-popover-item {
        display: block;
        padding: 5px 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .user-info-simple {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0;
        text-align: right;
        .user-link {
            @include inlineBlock();
            padding: 0 30px;
            margin: 5px 0;
            line-height: 20px;
            border-left: 1px solid $gray;
            &:first-child {
                border: none;
            }
        }
    }
</style>
