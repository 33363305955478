<template>
    <header class="header">
        <section class="logo">
            <router-link to="/">
                <img class="logo" :src="require('@a/img/logo.png')" alt="kamisoul">
            </router-link>
        </section>
        <div class="nav-wrapper">
            <ul class="nav-list">
                <li>
                    <router-link to="/">
                        <i class="icon icon-home-normal" />
                        <span class="txt">首页</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/news">
                        <i class="icon icon-zixun-normal" />
                        <span class="txt">资讯</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/product">
                        <i class="icon icon-product-normal" />
                        <span class="txt">产品</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/introduction">
                        <i class="icon icon-about-normal" />
                        <span class="txt">介绍</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <user-info />
    </header>
</template>
<script>
import userInfo from './userInfo'
export default {
    name: 'PageHeader',
    components: {
        userInfo
    }
}
</script>
<style lang="scss" scoped>
    .header {
        position: relative;
        z-index: 10;
        min-width: 1200px;
        height: 50px;
        border-bottom: 1px solid $gray;
        font-size: 14px;
        background-color: $white;
    }
    .logo {
        width: 180px;
        height: 100%;
        @include inlineBlock;
    }
    .nav-wrapper {
        @include inlineBlock;
        margin: 0 50px;
        width: calc(100% - 500px);
        height: 100%;
    }
    .nav-list {
        li {
            @include inlineBlock;
            width: calc((100% - 100px) / 4);
            a {
                display: block;
                padding: 0 20px;
                @include lineHeight(50px);
                .txt {
                    @include inlineBlock;
                    padding: 0 10px;
                }
            }
        }
    }
</style>
